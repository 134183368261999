.modal1 {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 1200px;
  width: 80%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.modal1-sm {
  max-width: 500px;
  width: 80%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal1-close {
  position: absolute;
  top: 0;
  right: 0;
  /* transform: translate(0, -100%); */
  padding: 15px;

  appearance: none;
  color: #000;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

.modal1-close:hover {
  box-shadow: none;
}

.modal1-header {
  padding-bottom: 5px;
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.modal1-content {
  overflow-y: auto;
  max-height: 80vh;
  padding: 30px;
  padding-top: 60px;
}

.modal1-payment .modal1-content {
  padding-top: 60px;
}

@media screen and (max-width: 767px) {
  .modal1-sm {
    /* width: 90%; */
  }

  .modal1-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
