#login,
#register {
  width: 100%;
  min-height: 100vh;
  background-color: #37acee;
  color: #fff;
}

#login .inner,
#register .inner {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

#login header,
#register header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  padding-top: 15px;
  color: #efefef;
  background-color: #005d81;
}

#login header svg,
#register header svg {
  height: 180px;
}

#login header h3,
#register header h3 {
  margin-top: 10px;
  /* font-size: calc(24vw * 100 / 1920); */
  font-size: 24px;
  font-weight: 600;
  color: #f8eb97;
}

#login .divider,
#register .divider {
  height: 6px;
  margin: 0 auto;
  background-color: #f7eb96;
}

#login-form,
#register-form {
  display: flex;
  flex-direction: column;
  padding: 15px;
}
