input,
button {
  margin: 0;
  padding: 0.5rem 0.75rem;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5;
  background-color: white;
  border: 1px solid #000;
  border-radius: 8px;
  /* box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2); */
}

button {
  appearance: none;
}

input.error[type="text"],
input.error[type="email"],
input.error[type="password"] {
  border-color: #dc3545;
}

form label.error {
  color: #dc3545;
}

form label {
  display: block;
}

form button[type="submit"],
form button[type="button"] {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  color: #000;
  background: linear-gradient(99deg, #d7b26f 0%, #f9ee98 100%);
  cursor: pointer;
}

form .form-row-inline label {
  display: inline-block;
  width: 8rem;
  padding-right: 10px;
}

form .form-row-inline input {
  width: 30rem;
}

form .form-row {
  /* display: flex;
  align-items: center; */
  display: flex;
  flex-direction: column;
  width: 100%;
}

form .form-row + .form-row {
  margin-top: 15px;
}

form .form-row:last-child {
  margin-top: 30px;
}

form .form-row-inline:last-child {
  display: flex;
  gap: 0.5rem;
  margin: 30px 0 0 8rem;
}

form .form-row2 {
  margin-top: 30px;
}

form .form-row-inline {
  display: flex;
  align-items: center;
}

form .check-label input[type="checkbox"] {
  margin-right: 10px;
}

form#profile-form label {
  /* width: 12rem; */
}

form#profile-form .form-row:last-child {
  margin-left: 12rem;
}

form#confirm-payment-form .form-row:last-child {
  margin-left: 0;
}

form#confirm-payment-form button[type="button"] {
  margin-left: 0;
  margin-right: 0;
}

form#payment-form .StripeElement {
  margin-bottom: 30px;
}

form#payment-form .form-row:last-child {
  margin-left: 0;
}

form#payment-form #btn-pay {
  width: 100%;
  color: #fff;
  background-color: #0570de;
  cursor: pointer;
}

form#payment-form .errorMessage {
  margin-top: 10px;
  font-size: 14px;
}
