#sidebar nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #sidebar {
    max-width: 120px;
  }

  #sidebar nav,
  #sidebar h1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
