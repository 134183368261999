#invoices .invoices-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

#invoices .invoices-list li {
  border: 1px solid #000;
}

#invoices .invoices-list li + li {
  border-top: none;
}

#invoices .invoices-list li a {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px;
  text-decoration: none;
}

#invoices .invoices-list li span {
  display: inline-block;
}
