.icon {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.icon::before {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
}

.icon-whatsapp::before,
.icon-whatsapp-alt::before {
  content: "'";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(../images/logo-whatsapp.svg);
}

.icon-whatsapp-alt::before {
  background-image: url(../images/logo-whatsapp-grey.svg);
}
