#home .home-images {
  /* display: flex;
  justify-content: center; */
}

#home .home-images img {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: calc(100vh - 4rem);
}

#home .home-images img + img {
  margin-top: 30px;
}
