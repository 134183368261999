#header .navbar-collapse.show {
  margin-top: 15px;
}

#header-bottom .navbar {
  background-color: #37acee;
}

#header-bottom .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

#header-bottom .nav-link.active {
  color: #fff;
}

#header-bottom .bottom-tab-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-bottom: 5px;
  text-align: center;
  color: #939aa1;
  background-color: #d9d9d9;
  border-radius: 50%;
}

#header-bottom .nav-link.active .bottom-tab-icon {
  color: rgba(0, 0, 0, 0.55);
  background-color: #fff;
}

#header-bottom .bottom-tab-label {
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 1200px) {
  #header-bottom .nav-link {
    padding: 0;
  }

  #header-bottom .bottom-tab-icon {
    margin-bottom: 0;
  }
}

@media (max-width: 576px) {
}
