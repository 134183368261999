#subscribe .react-dropdown-select-input {
  display: none;
}

#subscribe form .form-row:last-child,
#profile form .form-row:last-child {
  margin-left: 0;
}

#subscribe form button[type="submit"],
#profile form button[type="submit"],
#confirm-payment-form button[type="submit"] {
  margin-left: 0;
  margin-right: 0;
}

#subscribe .react-dropdown-select-dropdown {
  z-index: 9999;
}

.modal-payment .powered-by {
  display: block;
  margin-top: 30px;
  font-size: 12px;
}
