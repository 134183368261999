html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: underline;
  color: inherit;
}

.text-bold {
  font-weight: 600;
}

.text-red {
  color: var(--bs-danger);
}

.list1 {
  margin: 0;
  padding: 0;
  margin-left: 30px;
}

.list1 > li + li {
  margin-top: 10px;
}

p.has-icon {
  display: flex;
  flex-wrap: wrap;
}

i.icon-clickable {
  cursor: pointer;
}

.info-box {
  margin-bottom: 30px;
  padding: 5px 15px;
  background-color: #ffe69c;
}

#root {
  flex-direction: column;
  height: auto;
  min-height: 100vh;
}

#detail {
  padding: 4rem;
  padding-top: 90px;
  padding-bottom: 110px;
}

#contact-banner a {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  #detail {
    padding-left: 15px;
    padding-right: 15px;
  }
}
